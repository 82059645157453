import { template as template_750f36dead024854870d63efd0a709bc } from "@ember/template-compiler";
const FKLabel = template_750f36dead024854870d63efd0a709bc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
