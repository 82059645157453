import { template as template_12d59527b7694f5e85b63ba7b9aacce3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_12d59527b7694f5e85b63ba7b9aacce3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
