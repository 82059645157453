import { template as template_a5c7724881104b6692988e405a1235dc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_a5c7724881104b6692988e405a1235dc(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
